.dsm-tooltip-light {
  .tooltip-inner {
    background-color: white;
    color: #1c1c1c;
    max-width: 70%;
    text-align: left;
    font-size: 16px;
    padding: 0px;
  }
}

.bs-tooltip-bottom {
  .tooltip-arrow {
    &::before {
      border-bottom-color: white !important;
    }
  }
}

.bs-tooltip-top {
  .tooltip-arrow {
    &::before {
      border-top-color: white !important;
    }
  }
}

.bs-tooltip-end {
  .tooltip-arrow {
    &::before {
      border-right-color: white !important;
    }
  }
}

.bs-tooltip-start {
  .tooltip-arrow {
    &::before {
      border-left-color: white !important;
    }
  }
}

@media (max-width: 768px) {
  .tooltip-info-icon {
    display: none;
  }
}