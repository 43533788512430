@import './common/responsive';

.main-header {
  height: 80px;
  box-sizing: border-box;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 28px;
      font-weight: 600;
      color: #1C1C1C;
      margin-bottom: 0px;
    }

    .welcome-user {
      display: flex;
      gap: 8px;
      align-items: center;

      .logout-main {
        display: flex;
        align-items: center;
        gap: 4px;
        padding: 2px 10px 2px 16px;

        border-radius: 4px;

        .user-name {
          margin-bottom: 0px;
          font-size: 16px;
          font-weight: 500;
          color: #1E1F1F;
        }

        .dropdown {
          .btn-success {
            background-color: transparent;
            border: none;
            padding: 2px;
          }

          .dropdown-toggle {
            &::after {
              border: none;
              margin: 0%;
            }
          }

          .dropdown-menu {
            padding: 2px;

            .dropdown-item {
              padding: 2px 4px;

              &:active {
                background-color: #f8f9fa;
              }

              img {
                margin-right: 4px;
              }

              .logout-text {
                font-size: 16px;
                color: #DA1414;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
  }
}

@include respond-to(md) {
  .main-header {
    .header {
      padding: 0px 0px 0px 16px;

      .title {
        font-size: 16px;
      }
    }
  }
}