.textfield-container {
    width: 100%;

    .view-mode {
        .dsm-textarea {
            border: unset !important;
            background: transparent !important;
            padding-left: unset !important;
        }

        .dsm-inputbox,
        .inputbox-lg {
            border: unset !important;
            background: transparent !important;
            padding-left: unset !important;
        }
    }

    .view-mode-text {
        overflow-wrap: break-word;
        white-space: pre-wrap;
        font-family: "Inter", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: black;
        margin-bottom: unset;
        padding: 6px 12px 6px 0px;
    }

    .dsm-textarea {
        resize: none;
        border-radius: 4px;
        width: 100%;
        font-weight: 400;
        padding: 6px 12px;
        font-size: 16px;
        color: #777777;
        border: 1px solid #E2E3E5;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        min-height: 80px;
        height: auto;

        &:focus {
            outline: 1px solid transparent;
            border: 1px solid #4398CD;
            box-shadow: none;

            &:hover {
                border: 1px solid #4398CD;
            }
        }

        &:hover {
            border: 1px solid grey;
        }

        &::placeholder {
            color: grey;
        }

        &:disabled {
            background-color: #E2E3E5;
            border: 1px solid #E2E3E5;
            color: #6b6c7e;

            &:hover {
                cursor: not-allowed;
            }
        }

        &::placeholder {
            color: #8D8D8D;
        }

        textarea::-webkit-input-placeholder {
            color: grey;
        }

        textarea:-moz-placeholder {
            /* Firefox 18- */
            color: grey;
        }

        textarea::-moz-placeholder {
            /* Firefox 19+ */
            color: grey;
        }

        textarea:-ms-input-placeholder {
            color: grey;
        }

        &::-webkit-scrollbar {
            width: 4px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            background-color: grey;
        }
    }

    .resize-text-area {
        resize: vertical;
    }

    .helper-text {
        font-weight: 400;
        line-height: 120%;
        letter-spacing: 0.4px;
    }


    .inputbox-label {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #1C1C1C;
        margin-bottom: 4px;

        .inputbox-mandatory-mark {
            color: #da1414;
        }
    }

    // Inputbox Large

    .inputbox-lg {
        position: relative;
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: black;
        height: 40px;
        background-color: white;
        border: 1px solid black;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:hover {
            border: 1px solid grey;
        }

        &:focus {
            border: 1px solid #4398CD;
            box-shadow: none;
        }

        &:disabled {
            background-color: grey;
            border: 1px solid grey;
            color: black;

            &:hover {
                cursor: not-allowed;
            }
        }

        &::placeholder {
            color: black;
        }
    }

    //Inputbox
    .dsm-inputbox {
        position: relative;
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        color: #777777;
        background-color: white;
        border: 1px solid #E2E3E5;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus-within {
            border: 1px solid #4398CD;
        }

        &:focus-visible {
            outline-color: #4398CD;
        }

        &:hover {
            border: 1px solid #4398CD;
        }

        &:focus {
            border: 1px solid #4398CD;
            box-shadow: none;
        }

        &:disabled {
            background-color: #E2E3E5;
            border: 1px solid #E2E3E5;
            color: #6b6c7e;

            &:hover {
                cursor: not-allowed;
            }
        }

        &::placeholder {
            color: grey;
        }
    }

    .inputbox-error-border {
        border: 1px solid #da1414;
        border-radius: 4px;
    }

    .inputbox-error-msg {
        --bs-text-opacity: 1;
        margin-top: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14.4px;
        color: #da1414;
        display: inline-block;
    }

    .helper-text {
        --bs-text-opacity: 1;
        margin-top: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: black;
        color: black;
    }

    .input-helper-text {
        --bs-text-opacity: 1;
        margin-top: 5px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: black;
        position: relative;
        bottom: 10px;
    }

    // prefix

    .dsm-inputbox-prefixcontainer {
        display: flex;
        align-items: center;
        width: 100%;
        height: 32px;
        border-radius: 4px;
        color: #272833;
        background-color: #ffffff;
        border: 1px solid #000000;
        overflow: hidden;

        &:focus-within {
            border: 1px solid #4398CD;
        }

        .prefix {
            color: #272833;
            background: #f1f2f5;
            padding: 8px;
            font-size: 16px;
        }

        .dsm-inputbox-prefix {
            flex-grow: 1;
            font-size: 16px;
            background: #ffffff;
            border: none;
            outline: none;
            line-height: 1.5;
            padding: 0.375rem 0.75rem;

            &::placeholder {
                color: #8D8D8D;
            }

            &:disabled {
                color: #6b6c7e;
                background-color: #f1f2f5;
                border: 1px solid #f1f2f5;

                &:hover {
                    cursor: not-allowed;
                }
            }
        }
    }

    .view-prefix {
        border: 1px solid #f1f2f5;

        .prefix {
            color: #6b6c7e;
        }
    }
}