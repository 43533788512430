// Buttons
@mixin commonButton {
  padding: 6px 12px;
  font-weight: 500;
  border-radius: 4px;
  font-size: 16px;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-16 px);
  }

  &:active {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transform: translateY(1px);
  }
}

@mixin disabledButton {
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    box-shadow: none;
    transition: none;

    &:hover {
      background: none;
      box-shadow: none;
      transition: none;
    }
  }
}

.dsm-btn-primary {
  @include commonButton;
  background-color: #4398CD;
  border: 1px solid #4398CD;
  color: #fff;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-1px);
    background-color: #307BAA;
    border: 1px solid #307BAA;
  }

  &:active {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transform: translateY(1px);
  }

  .btn-icon {
    position: relative;
    right: 4px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.dsm-btn-secondary {
  @include commonButton;
  color: #4398CD;
  background-color: #fff;
  border: 1px solid #4398CD;

  .btn-icon {
    position: relative;
    right: 4px;
  }

  &:hover {
    background-color: #E5F5FF;
    border-color: #307BAA
  }

  @include disabledButton;
}

.dsm-btn-light {
  @include commonButton;
  color: #4398cd;
  // font-size: 18px;
  background-color: #fff;
  border: 1px solid #E2E3E5;

  .btn-icon {
    position: relative;
    right: 4px;
  }

  &:hover {
    background-color: #E5F5FF;
    border-color: #E2E3E5
  }


}

.delete-icon-btn {
  @include commonButton;
  padding: 5px 10px;
  color: #da1414;
  background-color: #fff;
  border: 1px solid #da1414;

  &:hover {
    background-color: #f1f2f5;
  }

  @include disabledButton;
}

.dsm-btn-danger {
  @include commonButton;
  color: #fff;
  background-color: #da1414;
  border: 1px solid #da1414;
  @include disabledButton;
}

.dsm-btn-danger-light {
  @include commonButton;
  color: #da1414;
  background-color: #fff;
  border: 1px solid #da1414;
  @include disabledButton;
}

.icon-btn {
  padding: 4px 6px;
}


// Badges

@mixin commonBadge {
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px
}

.success-badge {
  @include commonBadge;
  background-color: #f0fdf4;
  color: #16a34a;
}

.pending-badge {
  @include commonBadge;
  background-color: #fff7ed;
  color: #ea580c;
}

.muted-badge {
  @include commonBadge;
  background-color: #F5F5F5;
  color: #525252;
}

.info-badge {
  @include commonBadge;
  border: 1px solid #4398cd;
  background-color: #fff;
  color: #4398cd;
  cursor: pointer;
}

// Slideout Modal
//Dsm Default Slider
.dsm-slider {
  max-width: 100%;
  background-color: #fff;

  .offcanvas-header {
    border-bottom: 1px solid #e7e7ed;
    padding: 0.91rem 0.91rem;
    border-radius: 8px 8px 0 0;
    background-color: #fff;
    max-height: 64px;
    min-height: 56px;
    justify-content: space-between;

    .btn-close {
      font-size: 13px;
    }

    .dsm-slider-title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #272833;
    }
  }

  .offcanvas-body {
    height: calc(100% - 130px);
    margin: 0 12px;
    background-color: #fff;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #000;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      // background: $textcolorl3;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      // background: $textcolorl3;
    }
  }

  .dsm-slider-footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-top: 1px solid #e7e7ed;
    justify-content: flex-end;
    padding: 16px;
    gap: 16px;
    border-radius: 0 0 8px 8px;
    background-color: #fff;
  }
}

// end Default Slider
// Small Slider
.dsm-slideout-small {
  width: 29.125rem !important;
}

// Medium Slider
.dsm-slideout-medium {
  width: 37.5rem !important;
}

// Large Slider
.dsm-slideout-large {
  width: 67.5rem !important;
}


// dsm modal popup{

.dsm-modal-popup {
  .modal-header {
    .modal-title {

      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #1C1C1C;

      img {
        position: relative;
        bottom: 1px;
      }
    }

    .btn-close {
      font-size: 13px;
      box-shadow: none;
    }

  }

  .modal-body {
    p {
      color: #777777;
      text-align: left;
    }
  }
}

// add user chips
.chip-section {
  overflow-x: hidden;
  overflow-y: auto;
  margin-top: 16px;

  .chip-container {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px;
    gap: 12px;

    .chip-item {
      display: flex;
      padding: 3px 6px;
      font-size: 14px;
      background: #c4e0f1;
      border-radius: 16px;
      font-weight: 600;
      color: #4398CD;
      justify-content: space-between;
      grid-gap: 5px;
      gap: 5px;
      align-items: center;

      .remove-item {
        cursor: pointer;
        position: relative;
        width: 14px;
        height: 14px;
        color: #6b6c7e;
      }
    }
  }
}

// radio button

.dsm-radio-btn {
  -webkit-appearance: none;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-top: 0.25em;
  margin-right: 0.45em;
  border: 2px solid #a7a9bc;
  border-radius: 50%;
  background: #ffffff;
  vertical-align: top;

  &:checked {
    border: 8px solid #4398CD;

    &:disabled {
      opacity: 0.5;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}

.dsm-radio-btn-disabled {
  -webkit-appearance: none;
  width: 1rem;
  height: 1rem;
  display: inline-block;
  margin-top: 0.25em;
  margin-right: 0.45em;
  border-radius: 50%;
  vertical-align: top;
  opacity: 0.9;
}

.disabled-item {
  border: 2px solid #a7a9bc;
  background: #a7a9bc;
  cursor: not-allowed;
}

// form label

.inputbox-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1C1C1C;
  margin-bottom: 4px;
}

// Service card
.service-card {
  background-color: #ffffff;
  padding: 12px 16px;
  border-radius: 6px;
  cursor: pointer;

  .card-body {
    .title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
}

.sup {
  vertical-align: super;
  font-size: 10px
}