@import './common/responsive';

.inner-body-container {
  box-sizing: border-box;
  height: 100%;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;


  .header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px 8px 0px 0px;
    margin-bottom: 16px;

    .search-bar-box {
      width: 300px;

      .search-bar {
        box-sizing: border-box;
        border: 1px solid #e2e3e5;
        border-radius: 4px;
        font-size: 14px;
        background-repeat: no-repeat;
        background-position: 0;
        background-position-x: 8px;
        background-size: 14px 14px;
        font-weight: 400;
        background-image: url("../images/search.svg");
        padding: 5px 5px 5px 35px;
        transition: width 0.4s ease-in-out;
        margin-bottom: 0;
        width: 100%;
        height: 32px;
        color: #777777;

        &:focus {
          transition: 0.2s ease-in;
          outline-color: #e2e3e5;
          border-color: #e2e3e5;
        }
      }

      [type="search"] {
        outline-color: gray;
        outline-offset: 0px;
      }
    }
  }

  .body {
    height: 530px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;


    /* width */
    &::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #f1f1f1;
      border-radius: 12px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #f1f1f1;
    }

    .view-fields {
      background: #f8f9fa;
      padding: 12px 16px;
      border-radius: 4px;
      margin-bottom: 16px;
      font-size: 24px;

    }

    .requests-table {
      width: 100%;
      border-collapse: collapse;
      color: #343330;
      font-size: 14px;
      font-weight: 500;

      thead {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: #eaeff1;
        z-index: 2;

        tr {
          th {
            font-size: 16px;
            padding: 12px 0px;
            background-color: #eaeff175;
            text-align: left;
            padding-left: 24px;
            color: #1c1c1c;
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #e7e7ed;

          td {
            padding: 12px 0px;
            text-align: left;
            padding-left: 24px;
            color: #777777;
            font-size: 16px;

            .copy-icon {
              cursor: pointer;
              position: relative;
              right: 8px;
            }

            .copy-icon:hover {
              transform: scale(1.2);
              transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
                0.3s box-shadow,
                0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
            }

            .copy-icon:active {
              transform: scale(0.9);
              transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
                0.3s box-shadow,
                0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
            }

            .link {
              color: #307baa;

            }

            &:nth-child(3) {
              text-align: left;
            }
          }
        }
      }

      .empty-screen-tr {
        border-bottom: none;
        position: relative;
        top: 80px;
      }
    }

    .inner-page-footer {
      display: flex;
      flex-direction: row-reverse;
      gap: 8px;
      padding: 12px 16px;
      justify-content: start;
    }
  }
}

.service-conatiner {
  background-color: transparent;

  .header {
    background-color: transparent;

    .sub-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }


  .body {
    height: auto;

    .sub-title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }
}

@include respond-to(sm) {
  .inner-body-container {
    .body {
      .requests-table {
        width: max-content;
      }
    }
  }
}

@include respond-to(md) {
  .inner-body-container {
    .body {
      overflow: auto;

      .requests-table {
        thead {
          tr {
            th {
              font-size: 12px;
            }
          }
        }

        tbody {
          tr {

            td {
              font-size: 10px;

              .connection-did {
                display: inline-block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }
  }
}