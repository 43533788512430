@import 'responsive';

.landing-page-main {
    height: 100vh;

    @include respond-to(lg) {
        height: 100%;
    }

    .body {
        padding: 50px 200px 50px 140px;
        color: #ffffff;
        background-color: #19253A;

        @include respond-to(lg) {
            padding: 50px;
            height: auto;
        }

        .brand-logo {
            margin-bottom: 16px;

            img {
                margin-bottom: 0px;
            }
        }

        h1 {
            margin-bottom: 16px;
            font-size: 32px;

        }

        p {
            margin-bottom: 16px;
            color: #9A9FA8;
            text-align: start;
        }

        .dsm-btn-primary {
            background-color: #0076FC;
            border-radius: 0px;
            border-color: #0076FC;
            height: 40px;

            @include respond-to(xsm) {
                width: 100% !important;
            }
        }

        .decentral-logo {
            img {
                margin-bottom: 0px;
            }

            @include respond-to(sm) {
                display: none;
            }

            @include respond-to(lg) {
                margin-bottom: 16px;
            }
        }
    }

    .footer {
        background-color: #111C2F;
        height: 40%;
        color: #ffffff;
        padding: 32px 150px;
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 20px;
        margin-bottom: 16px;

        @include respond-to(xl) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include respond-to(lg) {
            grid-template-columns: repeat(2, 1fr);
            height: auto;
            padding: 32px 50px;
            margin-bottom: 0px;
        }

        @include respond-to(md) {
            grid-template-columns: repeat(1, 1fr);
        }

        .item {
            text-align: start;

            .item-logo {
                margin-bottom: 16px;
                border-radius: 50%;
                padding: 16px;
                background-color: #19253A;
                height: 65px;
                width: 65px;

                @include respond-to(md) {
                    display: none;
                }
            }

            h4 {
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 8px;

                @include respond-to(md) {
                    margin-top: 20px;
                }
            }

            p {
                color: #9A9FA8;
                margin-bottom: 0px;
            }
        }
    }
}