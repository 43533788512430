@import url("./_dashboard.scss");
@import url("./_header.scss");
@import url("./_sidebar.scss");
@import url("./_requests.scss");
@import url("./common/_customtoaster.scss");
@import url("./common/_textfield.scss");
@import url("./common/_stepper.scss");
@import url("./_components.scss");
@import url("../../components/userLogin/styles.scss");
@import url("./common/widget.scss");
@import './common/responsive';

@import "~bootstrap/scss/bootstrap";
@import "react-loading-skeleton/dist/skeleton.css";

* {
  font-family: "Poppins";
}

body {
  margin: 0px auto;
}

.background {
  background-color: #19253a;
}

.main-layout {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 0px;
  margin: 0px auto;
  overflow: hidden;
  position: relative;

  .wizard-btn {
    position: absolute;
    left: 24px;
    bottom: 24px;
    background-color: #ffffff;
    border-radius: 50px;
    padding: 8px 12px;
    border: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    img {
      margin-right: 8px;
    }

    @include respond-to(md) {
      display: none;
    }
  }

  .sidebar-menu {
    min-width: 250px;
    transition: ease-out 0.2s;
  }

  .collapsed-menu {
    min-width: 60px;
    transition: ease-in 0.2s;

    .collapse-brand-logo {
      width: 80px;
      padding: 30px 8px;

      img {
        @include respond-to(md) {
          width: 36px;
        }
      }

    }

  }

  .right-container {
    width: calc(100% - 60px);
    background-color: #eaeff1;
    overflow: auto;
    padding: 16px 32px;

    @include respond-to(md) {
      width: 100%;
      padding: 12px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .inner-container {
      box-sizing: border-box;
      border-radius: 8px;

      .all-services-cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 16px;

        @include respond-to(xl) {
          grid-template-columns: repeat(4, 1fr);
        }

        @include respond-to(lg) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include respond-to(md) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 12px;
          margin-bottom: 8px;
        }

        @include respond-to(sm) {
          grid-template-columns: repeat(1, 1fr);
          grid-gap: 12px;
          margin-bottom: 8px;
        }

        .service-card {
          p {
            font-size: 14px;
            margin-bottom: 0px;
          }
        }
      }

      .services-data-section {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 32px;
        margin-bottom: 16px;

        @include respond-to(xl) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include respond-to(lg) {
          grid-template-columns: repeat(2, 1fr);
        }

        @include respond-to(md) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 12px;
          margin-bottom: 8px;
        }
      }

      .issued-credentials-card-section {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 32px;
        margin-bottom: 16px;

        @include respond-to(xl) {
          grid-template-columns: repeat(3, 1fr);
        }

        @include respond-to(lg) {
          grid-template-columns: repeat(2, 1fr);
        }

        @include respond-to(md) {
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 12px;
          margin-bottom: 8px;
        }
      }
    }

  }
}


.tooltip {
  font-size: 13;
  text-align: justify;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.text-color-primary {
  color: #4398cd;
  font-size: 24px
}

.text-color-success {
  color: #f0fdf4;
  font-size: 24px
}

.text-color-warning {
  color: #fff7ed;
  font-size: 24px
}

.text-label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #1C1C1C;
  margin-bottom: 4px;
}