@mixin commonCard {
    border-radius: 4px;
    background-color: #ffffff;
    padding: 12px 16px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 16px;
}

.home-body {
    .update-card {
        @include commonCard;
        height: 100px;
        border-bottom: 5px solid;
        border-color: #0076FC;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #212121;

        img {
            height: 42px;
            width: 42px;
        }

        .card-right {
            text-align: right;

            .title-1 {
                font-size: 24px;
                font-weight: 600;
                color: #272832;
            }

            .title-2 {
                font-size: 16px;
                font-weight: 500;
                color: #605e5c;
            }
        }
    }

    .health-card {
        border-color: #5CBD99;

        .card-right {

            .title-1 {

                color: #5CBD99;
            }

            .title-2 {

                color: #5CBD99;
            }
        }
    }

    .graph-card {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @include commonCard;

        .title-1 {
            font-size: 24px;
            font-weight: 600;
            color: #272832;
        }

        .title-2 {
            font-size: 20px;
            font-weight: 500;
            color: #605e5c;
        }

        .credential-type-chart {

            // width: 250px;
            // height: 260px;
            // canvas {
    //     height: 250px;
    // }
        }
    }

    .nodes-card {
        height: 250px;
        @include commonCard;
        border-left: 7px solid;
        border-color: #0076FC;
        color: #212121;

        .node-title {
            font-weight: 700;
            font-size: 14px;
        }
    }

    .identity-card {
        height: 250px;
        @include commonCard;
        padding: 0px;

        .title {
            font-size: 16px;
            font-weight: 500;
            color: #212121;
            border-bottom: 1px solid #E0E0E0;
            padding: 12px 16px;
        }

        .description {
            padding: 12px 16px;
        }
    }

    h4 {
        font-size: 20px;
        font-weight: 500;
        color: #1E1F1F;
    }
}